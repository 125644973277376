import 'tailwindcss/base.css';
import { css } from "styled-components/macro"; //eslint-disable-line
import './global.css';

import {
  BrowserRouter,
  createBrowserRouter,
  Link,
  Outlet,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";

import Header from './components/header/header';
import Hero from './components/hero/hero';
import JoinToUs from './components/steps/steps';
import FAQS from './components/faqs/faqs';
import Contact from './components/contact/contact';
import Footer from './components/footer/footer';
import { Container, Content2Xl } from './components/misc/Layouts';

import AnimationRevealPage from './helpers/AnimationRevealPage';
import AboutUs from './pages/aboutUs';

const router = createBrowserRouter([
  { path: "*", Component: Root },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

function Home() {
    return (
        <AnimationRevealPage>
            <Header />
            <Hero />
            <FAQS />
            <JoinToUs />
            <Contact />
            <Footer />
        </AnimationRevealPage>
    )
}

function Root() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/about-us" element={<AboutUs />} />
        <Route exact path="/" element={<Home />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <>
      <Container>
        <Content2Xl>
          <Outlet />
        </Content2Xl>
      </Container>
    </>
  );
}