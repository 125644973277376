import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import { SectionHeading } from "../../components/misc/Headings";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";

const Heading = tw(SectionHeading)`w-full`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion.custom(styled.span`
  ${tw`ml-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  subheading = "Propozycje",
  heading = "Propozycje spotkań",
  description = "Angażujemy się w różne inicjatywy",
  faqs = [
    {
      question: "Wyspa Skarbów – spotkania mam z małymi dziećmi",
      answer: "Na Wyspie Skarbów spotykają się mamy z ich najmłodszymi skarbami. Zamiast siedzieć w domu i frustrować się ilością obowiązków – raz w tygodniu przejmują salę DROPS, by porozmawiać ze sobą lub zaproszonym gościem, pomodlić się i odpocząć od codziennej rutyny. W tym czasie dzieci bawią się w kąciku zabaw albo śpią 🙂 W razie potrzeby można skorzystać z porady duchowej lub psychologicznej.\n\n Zajęcia odbywają się w środy o 10:00 w sali DROPS w klasztorze (wejście obok furty).\n\n Kontakt: Maria Cichocka (wyspa.skarbow.drops@gmail.com)"
    },
    {
      question: "Klub Kobiety",
      answer: "To miejsce dla mam, które potrzebują chwili wytchnienia. Jest okazją do integracji, rozmowy, odstresowania i wymiany doświadczeń. W luźnej atmosferze w salce DROPS lub „na mieście” spotkamy się, żeby pogadać o tematach innych niż te, którymi żyjemy na co dzień. Czasem zapraszamy gości specjalnych – specjalistów od tego, co dla kobiet ważne 🙂 Organizujemy też wspólne wietrzenie szaf i giełdę ubrań, zamawiamy pizzę albo umawiamy się na film. \n\n Spotykamy się w czwartki. Po najbliższy termin – odezwij się do nas.\n\nKontakt: Dagmara Brzezińska (de.be1 małpka wp.pl)"
    },
    {
      question: "Klub Ojca",
      answer: "To nieformalne spotkania mężczyzn, którzy chcą doskonalić się w najtrudniejszej roli taty. Organizujemy ciekawy czas – za każdym razem wyszukujemy inną, „męską” atrakcję. To okazja by się spotkać, poznać, zżyć i budować zaufanie. Laserowy paintball, gokarty, szermierka, escape room, trampoliny – ty tylko niektóre z miejsc, jakie odwiedziliśmy. Najczęściej towarzyszy nam też nasz Duszpasterz. Na koniec siadamy żeby coś zjeść i porozmawiać o tym, co dla nas ważne. W luźnej atmosferze, bez stresu. Tak, jak faceci czasem potrzebują pogadać 🙂"
    },
    {
      question: "Spotkania ze Słowem",
      answer: "To nasza najnowsza inicjatywa. Postanowiliśmy lepiej poznać Pismo Święte. Pod okiem o. Wojciecha Garbata czytamy, rozważamy i omawiamy wybrane fragmenty. Jest na czas na wyjaśnienie tego, co niezrozumiałe, podzielenie się wątpliwościami, postawienie ważnych pytań. Do udziału nie jest wymagany doktorat z teologii fundamentalnej. Nie trzeba być znawcą Biblii ani krasomówcą. Można tylko kontemplować, słuchać, można pytać lub podzielić się tym, co zrodziło w nas Słowo. W wolności i wzajemnym szacunku."
    },
    {
      question: "Akcje specjalne",
      answer: "Nieregularnie organizujemy jednorazowe inicjatywy. Sprzedawaliśmy jabłka z dominikańskiego sadu, by zebrać środki dla potrzebujących. Zapełniliśmy dolny kościół mężczyznami podczas rekolekcji z Przymierzem Wojowników. Zorganizowaliśmy dwie edycje warsztatów dla ojców z Tato.Net. W naszej sali odbywają się warsztaty dla kobiet i mężczyzn z różnych dziedzin. Szukamy pomysłów na kolejne przedsięwzięcia. Jeśli chcielibyście się włączyć lub uruchomić ciekawą inicjatywę – przyjdźcie do nas, może warto zrobić to razem?"
    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container id="inicjatives">
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            <Heading>{heading}</Heading>
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    //transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  //transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer.split('\n').map((i, k) => (<p style={{display:"inline-block", width:"100%"}} key={k}>{i}</p>))}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};
