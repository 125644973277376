import React from "react";
import AnimationRevealPage from '../helpers/AnimationRevealPage.js';
import { Container, ContentWithPaddingXl } from '../components/misc/Layouts';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { SectionHeading } from "../components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

function AboutUs ({ headingText = "O nas" }) {
    return (
        <AnimationRevealPage>
            <Header />
            <Container>
                <ContentWithPaddingXl>
                    <HeadingRow>
                        <Heading>{headingText}</Heading>
                    </HeadingRow>
                    <Text>
                        <h2>Początek</h2>
                        <p>Jak to się wszystko zaczęło? Jeszcze parę lat temu zupełnie się nie znaliśmy. Łączyło nas to, że chodziliśmy z dziećmi na „jedenastki”. Był 2012 rok. Msze Św. o godz. 11:00 niedawno od o. Krzysztofa Michałowskiego przejął o. Michał Kubicz. Miała to być kolejna, zwykła niedziela. Tymczasem o. Michał podczas ogłoszeń podzielił się planem, by powołać inicjatywę dla rodzin. „Jeśli ktoś z Was czuje taką potrzebę – zapraszam”. I tak w wyznaczonym terminie pojawiliśmy się w klasztornej sali. Wkrótce potem z pomocą klasztoru wyremontowaliśmy salę przejętą od „Rejsu”. Było co remontować :) Ale po zmianach sala służy nam dzielnie.</p>

                        <h2>Dlaczego DROPS?</h2>
                        <p>Jesteśmy jednym z „najmłodszych” stażem i średnią wieku duszpasterstw przy Klasztorze oo. Dominikanów na Służewie. I chyba jedynym, w którym średnia się ciągle obniża wraz z kolejnymi pociechami, które przychodzą na świat. Gdy szukaliśmy nazwy spodobał się nam skrót DROPS, który idealnie układał się w „Duszpasterstwo Rodzin OP Służew”. I tak zostało. Chwilę potem powstało logo – kolorowe i wesołe, bo tacy chcemy być.</p>

                        <h2>DROPS, czyli kto?</h2>
                        <p>Jesteśmy rodzinami z różnych stron Warszawy. Mamy dzieci od kilkudniowych do kilkunastoletnich, lubimy Służew i pewnego dnia uznaliśmy, że w grupie łatwiej będzie pokonywać wyzwania. Wspieramy się, dzielimy, wspólnie poszukujemy odpowiedzi na ważne pytania o Boga, miłość małżeńską, wychowanie dzieci. Jesteśmy otwarci na wszystkich, którzy chcą spróbować czegoś więcej.</p>

                        <h2>Jak to działa?</h2>
                        <p>Spotykamy się co dwa tygodnie. Modlimy się, dyskutujemy, śmiejemy. W tym czasie młodsze dzieci mają opiekę, a starsze – katechezę. Dwa razy do roku wyjeżdżamy na rodzinne rekolekcje, a w lecie na wakacyjny wyjazd. Lubimy się i lubimy, gdy pojawia się ktoś „nowy”, kogo możemy poznać. Robimy spotkania plenerowe, wyprawiamy się na kajaki, grillujemy w ogrodach klasztornych. Organizujemy też specjalne inicjatywy skierowane „na zewnątrz” – akcje charytatywne, rekolekcje, Drogę Krzyżową dla mężczyzn czy warsztaty dla ojców. Opiekuje się nami Duszpasterz – o. Marek Krysztopik, który prowadzi formację duchową i towarzyszy nam w drodze. Bywa poważnie, bywa wesoło, jak to u Dominikanów.</p>

                        <h2>Jacy jesteśmy?</h2>
                        <p>Nie jesteśmy idealni, nie uprawiamy propagandy religijnego sukcesu i nie udajemy, że kierowanie się na co dzień zasadami wiary to bułka z masłem. Ale chcemy próbować i wiemy, że razem łatwiej iść do przodu. Zależy nam na kontakcie z ludźmi podobnymi do nas. Cieszymy się, że dzieci znajdują tu ciekawych rówieśników. Rodzą się nowe przyjaźnie, mamy z kim pogadać, poradzić się, wymienić doświadczeniami albo ubrankami dla dzieci. To dla nas ważne miejsce. Jesteśmy wdzięczni oo. Dominikanom za otwartość na rodziny i ich problemy. Jeśli jest Wam trudno, szukacie swojego miejsca, brakuje Wam czegoś w codziennym zabieganiu – zapraszamy do DROPSa!</p>
                    </Text>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};

export default AboutUs;
