import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

import { SectionHeading } from "../../components/misc/Headings";
import { SectionDescription } from "../../components/misc/Typography";
import { Container, ContentWithVerticalPadding } from "../../components/misc/Layouts";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/dot-pattern.svg";
import SelfieImg from "../../images/banner.jpg";

const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-2 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(SectionHeading)`text-left text-primary-900 leading-snug xl:text-6xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full sm:rounded-default relative z-20`;

export default ({
    heading = "Duszpasterstwo Rodzin OP Służew",
    description = "Celem naszego duszpasterstwa jest odnowa i pogłębienie relacji z Bogiem oraz ze swoimi najbliższymi, z którymi tworzymy jeden dom, wynikający z sakramentu małżeństwa oraz z rodzicielstwa.",
    imageSrc = SelfieImg,
    imageDecoratorBlob = true
}) => {
    return (
            <Container>
                <ContentWithVerticalPadding>
                    <Row>
                        <TextColumn>
                            <Heading>{heading}</Heading>
                            <Description>{description}</Description>
                        </TextColumn>
                        <ImageColumn>
                            <ImageContainer>
                                <Image src={imageSrc} />
                            </ImageContainer>
                        </ImageColumn>
                    </Row>
                </ContentWithVerticalPadding>
            </Container>
    );
};
