import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "../..//components/misc/Headings.js";
import EmailIllustrationSrc from "../../images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

export default ({
                    subheading = "Contact Us",
                    heading = <>Chcesz <span tw="text-primary-500">dowiedzieć się</span><wbr/> więcej?</>,
                    description = <>Napisz do nas na adres: <a href="mail:drops.sluzew@dominikanie.pl"><span tw="text-primary-500">drops.sluzew@dominikanie.pl</span></a></>,
                    submitButtonText = "Send",
                    formAction = "#",
                    formMethod = "get",
                    textOnLeft = true,
                }) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

    return (
        <Container id="contact">
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={EmailIllustrationSrc} />
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Heading>{heading}</Heading>
                        {description && <Description>{description}</Description>}
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
